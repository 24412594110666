import logo from './logo.svg';
import Aperture from './Aperture.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Christiaan Visagie</h1>
        <img src={Aperture} className="App-logo" alt="logo" />
        <strong>
         Coming Soon
        </strong>
        <a
          className="App-link"
          href="https://cv.christiaanvisagie.co.za"
          target="_blank"
          rel="noopener noreferrer"
        >
          Resume/CV
        </a>
      </header>
    </div>
  );
}

export default App;
